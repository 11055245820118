@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@tailwind base;
@tailwind components;
@tailwind utilities;

.skeleton-loading {
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  animation: shine-avatar 1.6s infinite linear;

  &.big-skeleton {
    background-size: 600px;
    animation: big-shine-avatar 1.6s infinite linear;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 260px;
}


@mixin invalid-form-elements {
  // border:1px solid #90CAF9;
  // &.ng-invalid.ng-touched {
  //   border: 1px solid #ff8a80;
  //   transition: all 300ms ease-out;

  //   &:not(.outline-none) {
  //     outline-color: #ff8a80;
  //   }
  // }
}

@mixin disabled-form-elements {
  background-image: linear-gradient(251deg, #cecedd 0%, #eeeef6 100%);
  background-position-x: 0% !important;
  background-position-y: 0% !important;
  color: #fff;
}

@layer components {
  textarea,
  input,
  select {
    @apply placeholder:text-head-200
  }

  mat-select {
    &.mat-mdc-select-disabled {
      .mat-mdc-select-trigger .mat-mdc-select-value .mat-mdc-select-value-text .mat-mdc-select-min-line {
        color: #fff;
      }

      @include disabled-form-elements;
    }

    @include invalid-form-elements;
  }

  .comment-image {
    @apply skeleton-loading mr-[8px] block h-[44px] w-[44px] flex-shrink-0 rounded-full;
  }

  .comment-wrapper {
    @apply min-h-[25px] w-full rounded-[8px] border-[1px] border-solid border-head-25 bg-head-20 px-[12px] py-[8px] text-[14px] font-medium;
  }

  .comment-actions {
    all: initial;
    @apply block cursor-pointer px-[16px] py-[8px] pl-[0px] font-poppins text-[16px] font-semibold text-head-300;
  }

  .slick-button {
    @apply flex h-[2.18rem] w-full items-center justify-center rounded-[.4rem] bg-head-50 p-0 text-head-300 hover:bg-purple-200 hover:text-white active:bg-purple-300 active:text-white lg:mr-[.6rem] lg:w-auto;
    @apply lg:w-[3.5rem] lg:min-w-[3.5rem];
  }

  .slick-main-button {
    @apply h-[44px] w-[178px] rounded-[8px] bg-purple-100 text-[14px] font-semibold leading-[20px] text-white hover:bg-purple-200;
  }

  .slick-option-button {
    @apply absolute bottom-[42px] h-[42px] w-[77px] rounded-[8px] bg-white;
  }

  .new-slick-header {
    @apply relative z-20 flex h-[36px] w-[36px] items-center justify-center rounded-full bg-head-50 group-[.active]:bg-purple-200 group-[.selected]:bg-purple-100;
    &:before,
    &:after {
      @apply absolute left-[36px] top-[18px] h-[4px] transition-all content-[''] last:hidden;
    }

    &:before {
      @apply z-0 w-[72px] bg-head-50;
    }

    &:after {
      @apply z-10 w-[0px] bg-purple-100 transition-all group-[.selected]:w-[72px];
    }

    &.hidden-lines {
      &:before {
        @apply w-[0px];
      }

      &:after {
        @apply w-[0px];
      }
    }
  }

  .admin-navbar-links {
    @apply flex h-[56px] items-center bg-transparent py-[18px] pl-[32px] text-[14px] leading-[20px] text-head-400 transition-all w-full xl:w-auto;
    &:hover {
      @apply bg-purple-11 text-head-400 transition-all;
    }

    &:active {
      @apply bg-purple-25 text-head-400 transition-all;
    }

    &.active {
      @apply bg-purple-200 text-white transition-all;
    }
  }

  .admin-buttons {
    @apply flex h-[44px] min-w-[120px] cursor-pointer flex-row items-center justify-center rounded-[8px] pl-[16px] pr-[16px] text-[14px] font-semibold leading-[20px] transition-all;
    &.primary {
      @apply border-[2px] border-purple-200 bg-purple-200 text-white transition-all;
      &:hover {
        @apply border-purple-50 bg-purple-50;
      }

      &:active {
        @apply border-purple-200 bg-purple-200;
      }

      &[disabled] {
        @apply pointer-events-none border-head-100 bg-head-100;
      }
    }

    &.secondary {
      @apply border-[2px] border-purple-200 bg-white text-purple-200 transition-all;
    }
  }
}

@layer utilities {
  .hamburger-toggle {
    @apply [&>div]:h-0 [&>div]:bg-white;
    @apply [&>div]:before:translate-y-[-12px] [&>div]:before:rotate-45;
    @apply [&>div]:after:translate-y-[12px] [&>div]:after:-rotate-45;
  }
}

@use '@angular/material' as mat;
@use '../slick-m3-theme';

// Badge
.badge-primary {
  @include mat.badge-color(slick-m3-theme.$light-theme, $color-variant: primary);
}
.badge-secondary {
  @include mat.badge-color(slick-m3-theme.$light-theme, $color-variant: secondary);
}
.badge-tertiary {
  @include mat.badge-color(slick-m3-theme.$light-theme, $color-variant: tertiary);
}
.badge-error {
  @include mat.badge-color(slick-m3-theme.$light-theme, $color-variant: error);
}

// Button
// Button-toggle
.button-primary {
  @include mat.button-color(slick-m3-theme.$light-theme, $color-variant: primary);
}
.button-secondary {
  @include mat.button-color(slick-m3-theme.$light-theme, $color-variant: secondary);
}
.button-tertiary {
  @include mat.button-color(slick-m3-theme.$light-theme, $color-variant: tertiary);
}
.button-error {
  @include mat.button-color(slick-m3-theme.$light-theme, $color-variant: error);
}

// Checkbox
.checkbox-primary {
  @include mat.checkbox-color(slick-m3-theme.$light-theme, $color-variant: primary);
}
.checkbox-secondary {
  @include mat.checkbox-color(slick-m3-theme.$light-theme, $color-variant: secondary);
}
.checkbox-tertiary {
  @include mat.checkbox-color(slick-m3-theme.$light-theme, $color-variant: tertiary);
}
.checkbox-error {
  @include mat.checkbox-color(slick-m3-theme.$light-theme, $color-variant: error);
}

// Chips
.chips-primary {
  @include mat.chips-color(slick-m3-theme.$light-theme, $color-variant: primary);
}
.chips-secondary {
  @include mat.chips-color(slick-m3-theme.$light-theme, $color-variant: secondary);
}
.chips-tertiary {
  @include mat.chips-color(slick-m3-theme.$light-theme, $color-variant: tertiary);
}
.chips-error {
  @include mat.chips-color(slick-m3-theme.$light-theme, $color-variant: error);
}

// Datepicker
.datepicker-primary {
  @include mat.datepicker-color(slick-m3-theme.$light-theme, $color-variant: primary);
}
.datepicker-secondary {
  @include mat.datepicker-color(slick-m3-theme.$light-theme, $color-variant: secondary);
}
.datepicker-tertiary {
  @include mat.datepicker-color(slick-m3-theme.$light-theme, $color-variant: tertiary);
}
.datepicker-error {
  @include mat.datepicker-color(slick-m3-theme.$light-theme, $color-variant: error);
}

// Fab
.fab-primary {
  @include mat.fab-color(slick-m3-theme.$light-theme, $color-variant: primary);
}
.fab-secondary {
  @include mat.fab-color(slick-m3-theme.$light-theme, $color-variant: secondary);
}
.fab-tertiary {
  @include mat.fab-color(slick-m3-theme.$light-theme, $color-variant: tertiary);
}

// Form-field
.form-field-primary {
  @include mat.form-field-color(slick-m3-theme.$light-theme, $color-variant: primary);
}
.form-field-secondary {
  @include mat.form-field-color(slick-m3-theme.$light-theme, $color-variant: secondary);
}
.form-field-tertiary {
  @include mat.form-field-color(slick-m3-theme.$light-theme, $color-variant: tertiary);
}
.form-field-error {
  @include mat.form-field-color(slick-m3-theme.$light-theme, $color-variant: error);
}
.form-field-neutral {
  @include mat.form-field-color(slick-m3-theme.$light-theme, $color-variant: primary);
  --mdc-filled-text-field-container-color: #000000;
}

// Icon
.icon-surface {
  @include mat.icon-color(slick-m3-theme.$light-theme, $color-variant: surface);
}
.icon-primary {
  @include mat.icon-color(slick-m3-theme.$light-theme, $color-variant: primary);
}
.icon-secondary {
  @include mat.icon-color(slick-m3-theme.$light-theme, $color-variant: secondary);
}
.icon-tertiary {
  @include mat.icon-color(slick-m3-theme.$light-theme, $color-variant: tertiary);
}
.icon-error {
  @include mat.icon-color(slick-m3-theme.$light-theme, $color-variant: error);
}

// Option
.option-primary {
  @include mat.option-color(slick-m3-theme.$light-theme, $color-variant: primary);
}
.option-secondary {
  @include mat.option-color(slick-m3-theme.$light-theme, $color-variant: secondary);
}
.option-tertiary {
  @include mat.option-color(slick-m3-theme.$light-theme, $color-variant: tertiary);
}
.option-error {
  @include mat.option-color(slick-m3-theme.$light-theme, $color-variant: error);
}

// Progress-bar
.progress-bar-primary {
  @include mat.progress-bar-color(slick-m3-theme.$light-theme, $color-variant: primary);
}
.progress-bar-secondary {
  @include mat.progress-bar-color(slick-m3-theme.$light-theme, $color-variant: secondary);
}
.progress-bar-tertiary {
  @include mat.progress-bar-color(slick-m3-theme.$light-theme, $color-variant: tertiary);
}
.progress-bar-error {
  @include mat.progress-bar-color(slick-m3-theme.$light-theme, $color-variant: error);
}

// Progress-spinner
.progress-spinner-primary {
  @include mat.progress-spinner-color(slick-m3-theme.$light-theme, $color-variant: primary);
}
.progress-spinner-secondary {
  @include mat.progress-spinner-color(slick-m3-theme.$light-theme, $color-variant: secondary);
}
.progress-spinner-tertiary {
  @include mat.progress-spinner-color(slick-m3-theme.$light-theme, $color-variant: tertiary);
}
.progress-spinner-error {
  @include mat.progress-spinner-color(slick-m3-theme.$light-theme, $color-variant: error);
}

// Pseudo-checkbox
.pseudo-checkbox-primary {
  @include mat.pseudo-checkbox-color(slick-m3-theme.$light-theme, $color-variant: primary);
}
.pseudo-checkbox-secondary {
  @include mat.pseudo-checkbox-color(slick-m3-theme.$light-theme, $color-variant: secondary);
}
.pseudo-checkbox-tertiary {
  @include mat.pseudo-checkbox-color(slick-m3-theme.$light-theme, $color-variant: tertiary);
}
.pseudo-checkbox-error {
  @include mat.pseudo-checkbox-color(slick-m3-theme.$light-theme, $color-variant: error);
}

// Radio
.radio-primary {
  @include mat.radio-color(slick-m3-theme.$light-theme, $color-variant: primary);
}
.radio-secondary {
  @include mat.radio-color(slick-m3-theme.$light-theme, $color-variant: secondary);
}
.radio-tertiary {
  @include mat.radio-color(slick-m3-theme.$light-theme, $color-variant: tertiary);
}
.radio-error {
  @include mat.radio-color(slick-m3-theme.$light-theme, $color-variant: error);
}

// Select
.select-primary {
  @include mat.select-color(slick-m3-theme.$light-theme, $color-variant: primary);
}
.select-secondary {
  @include mat.select-color(slick-m3-theme.$light-theme, $color-variant: secondary);
}
.select-tertiary {
  @include mat.select-color(slick-m3-theme.$light-theme, $color-variant: tertiary);
}
.select-error {
  @include mat.select-color(slick-m3-theme.$light-theme, $color-variant: error);
}

// Slide-toggle
.slide-toggle-primary {
  @include mat.slide-toggle-color(slick-m3-theme.$light-theme, $color-variant: primary);
}
.slide-toggle-secondary {
  @include mat.slide-toggle-color(slick-m3-theme.$light-theme, $color-variant: secondary);
}
.slide-toggle-tertiary {
  @include mat.slide-toggle-color(slick-m3-theme.$light-theme, $color-variant: tertiary);
}
.slide-toggle-error {
  @include mat.slide-toggle-color(slick-m3-theme.$light-theme, $color-variant: error);
}

// Slider
.slider-primary {
  @include mat.slider-color(slick-m3-theme.$light-theme, $color-variant: primary);
}
.slider-secondary {
  @include mat.slider-color(slick-m3-theme.$light-theme, $color-variant: secondary);
}
.slider-tertiary {
  @include mat.slider-color(slick-m3-theme.$light-theme, $color-variant: tertiary);
}
.slider-error {
  @include mat.slider-color(slick-m3-theme.$light-theme, $color-variant: error);
}

// Stepper
.stepper-primary {
  @include mat.stepper-color(slick-m3-theme.$light-theme, $color-variant: primary);
}
.stepper-secondary {
  @include mat.stepper-color(slick-m3-theme.$light-theme, $color-variant: secondary);
}
.stepper-tertiary {
  @include mat.stepper-color(slick-m3-theme.$light-theme, $color-variant: tertiary);
}
.stepper-error {
  @include mat.stepper-color(slick-m3-theme.$light-theme, $color-variant: error);
}

// Tabs
.tabs-primary {
  @include mat.tabs-color(slick-m3-theme.$light-theme, $color-variant: primary);
}
.tabs-secondary {
  @include mat.tabs-color(slick-m3-theme.$light-theme, $color-variant: secondary);
}
.tabs-tertiary {
  @include mat.tabs-color(slick-m3-theme.$light-theme, $color-variant: tertiary);
}
.tabs-error {
  @include mat.tabs-color(slick-m3-theme.$light-theme, $color-variant:error);
}

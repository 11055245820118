@use '@angular/material' as mat;
@use './slick-m3-theme';

@include mat.core();

:root {
  @include mat.all-component-themes(slick-m3-theme.$light-theme);
}

@import 'tailwind-custom.scss';
@import "./m3-variants/buttons.scss";
@import 'overwrite-material-design-styles';
@import 'overwrite-m3-styles';

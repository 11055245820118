//MARK: Modal's background
body .cdk-overlay-container {
  .mat-mdc-dialog-surface {
    background-color: #ffffff;
    border-radius: 20px;
  }

  .slick-change-password {
    .mat-horizontal-stepper-header-container {
      display: none;
    }

    .mat-horizontal-content-container {
      padding: 0;
    }
  }

  .change-profile-picture-modal {
    .mat-horizontal-stepper-header-container {
      display: none;
    }

    .mat-horizontal-content-container {
      padding: 0;
    }
  }

  .bulk-create-users {
    .mat-horizontal-stepper-header-container {
      display: none;
    }

    .mat-horizontal-content-container {
      padding: 0;
    }
  }

  //MARK: Bottom-sheet
  .cdk-global-overlay-wrapper {
    .cdk-overlay-pane.slick-full-height {
      @apply h-screen lg:h-auto;
      mat-bottom-sheet-container {
        @apply max-h-full p-0 lg:max-h-[80vh];
      }
    }
  }
}

//MARK: Modal with scroll
body.scroll-modal {
  margin-right: 5px;
  overflow: hidden;
}

body.scroll-modal header {
  position: relative;
  left: -2px;
}

body.scroll-modal .cdk-global-overlay-wrapper {
  overflow-y: scroll;
}

//MARK: Scroll
.hiddenScroll {
  &::-webkit-scrollbar {
    width: 1px;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #90a4ae;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: hsl(202, 11%, 56%);
}

mat-snack-bar-container.slick-custom-snackbar-success .mdc-snackbar__surface,
mat-snack-bar-container.slick-custom-snackbar-error .mdc-snackbar__surface,
mat-snack-bar-container.slick-custom-snackbar-warning .mdc-snackbar__surface {
  background-color: white;
}

mat-snack-bar-container.slick-custom-snackbar-success .mdc-snackbar__surface .mdc-button__label,
mat-snack-bar-container.slick-custom-snackbar-error .mdc-snackbar__surface .mdc-button__label,
mat-snack-bar-container.slick-custom-snackbar-warning .mdc-snackbar__surface .mdc-button__label {
  color: #fff;
}

// menu style
body .mat-mdc-menu-panel {
  border-radius: 12px;
  padding: 10px 0;

  .mat-mdc-menu-content {
    padding: 0;
  }

  .mat-mdc-menu-item {
    padding: 0 16px;
    border-radius: 8px;
    min-height:38px;

    &:hover {
      background-color: #eceff1;
    }
  }

  mat-icon {
    margin-left: 16px;
    margin-right: 8px;
  }
}

.cdk-overlay-container {
  //Profile menu
  .cdk-overlay-backdrop.profile-menu ~ div {
    transform: translateY(20px);
  }

  //notifications menu
  .notification-menu ~ div {
    .mat-mdc-menu-panel {
      max-width: 290px;
      width: 290px;
    }
  }
}

// .mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item {
//   display: block;
//   text-align: center;
//   padding: 0;
// }

/* Slick tags */
@mixin select-styles {
  line-height: 60px;
  font-weight: lighter;
  font-size: 24px;
  --tw-text-opacity: 1;
  color: rgb(146 142 170 / var(--tw-text-opacity));
}

.mat-mdc-select.select-slick-tags,
.mat-mdc-select.select-topic-tags {
  .mat-mdc-select-trigger {
    padding-left: 15px;
  }

  .mat-mdc-select-placeholder {
    font-size: 14px;
    font-weight: 600;
    color: #eceff1;
  }

  .mat-mdc-select-value .mat-mdc-select-value-text .mat-mdc-select-min-line {
    // @include select-styles;
  }
}

.mat-mdc-select.select-slick-form {
  border-radius: 8px;

  .mat-mdc-select-trigger {
    height: 100%;
    padding: 0 10px;
  }
}

.mat-mdc-option.mdc-list-item,
.mat-mdc-option:focus.mdc-list-item,
.mat-mdc-option.mdc-list-item.mat-mdc-option-multiple,
.mat-mdc-option.mat-mdc-option-active.mdc-list-item,
.mat-mdc-option {
  // @include select-styles;
}

.mat-mdc-option.mdc-list-item.mdc-list-item--selected span {
  font-weight: bold;
}

/*MARK: Swiper */
:root {
  --swiper-scrollbar-drag-bg-color: transparent linear-gradient(76deg, #15c7ff 0%, #ff3adf 100%) 0% 0% no-repeat
    padding-box;
}

.swiper-container-latest-slicks {
  .swiper-initialized {
    padding-bottom: 32px;
  }
}

.skeleton-loading {
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  animation: shine-avatar 1.6s infinite linear;

  &.big-skeleton {
    background-size: 600px;
    animation: big-shine-avatar 1.6s infinite linear;
  }
}

@keyframes shine-avatar {
  0% {
    background-position: -32px;
  }
  40%,
  100% {
    background-position: 208px;
  }
}

@keyframes big-shine-avatar {
  0% {
    background-position: -200px;
  }
  40%,
  100% {
    background-position: 1200px;
  }
}

/* MARK: playlist modal */
.add-to-playlist {
  .mat-mdc-checkbox.mat-mdc-checkbox-checked {
    .mdc-checkbox:not(:disabled):active .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__native-control:enabled:focus:checked ~ .mdc-checkbox__background,
    .mdc-checkbox__native-control.mdc-checkbox--selected ~ .mdc-checkbox__background {
      background-color: #15c7ff;
      border-color: #15c7ff;
    }

    .mdc-checkbox__ripple {
      display: none;
    }
  }

  .mat-mdc-checkbox:not(.mat-mdc-checkbox-checked) {
    .mdc-checkbox
      .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate='true'])
      ~ .mdc-checkbox__background {
      background-color: #eeeef6;
      border-color: #eeeef6;
    }

    .mdc-checkbox__ripple {
      display: none;
    }
  }
}

/*
* MARK: Tooltip
*/

.cdk-overlay-pane {
  mat-tooltip-component {
    .mdc-tooltip.mat-mdc-tooltip {
      &.mdc-tooltip--shown {
        .mdc-tooltip__surface {
          background: #fff;
          padding: 8px;
          border-radius: 5px;
          font-weight: bold;
          border: 2px solid #15c7ff;
          font-size: 18px;
          color: #000;
        }
      }
    }
  }
}

// MARK: teleprompter style

.teleprompter-form mat-slider {
  width: 100%;
}

.teleprompter-form .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #ff3adf;
  --mdc-slider-active-track-color: #15c7ff;
  --mdc-slider-inactive-track-color: #15c7ff;
  --mdc-slider-with-tick-marks-active-container-color: #ff3adf;
  --mdc-slider-with-tick-marks-inactive-container-color: #ff3adf;
}

//MARK: image-cropper

.change-profile-imagem image-cropper > div,
.change-thumbnail-imagem image-cropper > div {
  width: unset !important;
}

//MARK: Snip video
body {
  .slick-plus-default-range.mat-mdc-slider {
    .mdc-slider__thumb--focused .mdc-slider__thumb-knob {
      background-color: darken(#ea80fc, 15%);
      border-color: darken(#ea80fc, 15%);
    }

    .mdc-slider__thumb-knob {
      background-color: #ea80fc;
      border-color: #ea80fc;
    }

    .mdc-slider__track--active_fill {
      border-color: #ea80fc;
    }

    .mdc-slider__track {
      background-color: #b0bec5;
      border-color: #b0bec5;
    }
  }

  .slick-plus-default-volume-range {
    .mdc-slider__thumb--focused .mdc-slider__thumb-knob {
      background-color: #fff;
      border-color: #fff;
    }

    .mdc-slider__thumb-knob {
      background-color: #fff;
      border-color: #fff;
    }

    .mdc-slider__track--active_fill {
      border-color: #b0bec5;
    }

    .mdc-slider__track {
      background-color: #b0bec5;
      border-color: #b0bec5;
    }
  }

  .mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle,
  .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: #ba68c8;
  }

  .slick-plus-custom-video {
    mat-slider.mat-mdc-slider.slick-plus-default-range,
    mat-slider.mat-mdc-slider.slick-plus-default-volume-range {
      mat-slider-visual-thumb {
        visibility: hidden;
      }
    }

    mat-slider.mat-mdc-slider.slick-plus-default-volume-range {
      .mdc-slider__track--active_fill {
        border-color: #ffffff;
      }
    }
  }
}

//MARK: top-navbar

.cdk-overlay-pane:not(.mat-mdc-autocomplete-panel-above) div.mat-mdc-autocomplete-panel.navbar-autocomplete {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.mat-ripple.absolute {
  position: absolute;
}

div.mat-mdc-autocomplete-panel.navbar-autocomplete {
  --mat-option-hover-state-layer-color: #eceff1;
  margin-top: 2px;
  padding: 14px 0;
  box-shadow: none;
  border-radius: 0;
  // box-shadow: 0px 4px 8px 0px #546e7a33;
  // border-radius: 12px;

  .mat-mdc-option.mdc-list-item--disabled .mdc-list-item__primary-text {
    opacity: 1;
  }

  .mat-mdc-option {
    padding-left: 14px;
    line-height: 40px;
    border-radius: 8px;

    mat-icon {
      margin-right: 8px;
    }

    .mdc-list-item__primary-text {
      display: flex;
      width: 100%;
    }
  }
}

@media (min-width: 1024px) {
  div.mat-mdc-autocomplete-panel.navbar-autocomplete {
    box-shadow: 0px 4px 8px 0px #546e7a33;
    border-radius: 12px;
  }
}

/* MARK: icons */
.mat-icon {
  &.hidden,
  &.sm\:hidden,
  &.md\:hidden {
    display: none;
  }
}

body{
  .mat-icon{
    width:1.2rem;
    height:1.2rem;
  }
}

.material-icons, .material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 1.2rem;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  font-variation-settings:'FILL' 0,'wght' 400,'GRAD' 0, 'opsz' 24;

  .mat-badge-content {
    font-family: "Roboto";
  }
  &.app-card-icon{
    width:50px;
    height:50px;
    font-size: 3rem;
    line-height: 1;
  }

  &.material-icons-outlined {
    font-family: 'Material Symbols Outlined';
  }
}

mat-form-field.video-component-mat-form-field .mdc-text-field--filled{
  border-radius:2rem!important;
}

// MARK: checkbox
mat-checkbox.mat-mdc-checkbox{
  transform:scale(.85);
}

//MARK: button slider

.mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon {
  display: none;
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
  display: none;
}

.mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
  background: #90a4ae !important;
}

.mdc-switch .mdc-switch__handle::before {
  background: #9c27b0 !important;
}

.mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__track::after{
  background: #eceff1 !important;
}

//MARK: teleprompter padding
.cdk-virtual-scroll-content-wrapper {
  padding: 1.75rem 1rem;
}

.invisible-mat-tab-header > mat-tab-header {
  display: none;
}

mat-tab-body > div[cdkscrollable] {
  overflow: hidden;
}

mat-progress-bar.notFullColor > div.mdc-linear-progress__bar > span {
  @apply border-red-400;
}

mat-progress-bar.fullColor > div.mdc-linear-progress__bar > span {
  @apply border-green-400;
}

[aiButtonTeleprompter].mat-mdc-icon-button {
  background-color: #833191;
}

$border-color: 1px solid #fceaff;

//MARK: admin tables
.admin-custom-table {
  .mat-mdc-table {
    border-radius: 16px;
    overflow: hidden;

    .mat-mdc-header-row {
      background-color: #fce9ff;
      min-height: 48px;

      .mat-mdc-header-cell {
        &:nth-child(1) {
          border-left: 1px solid #fce9ff;
          min-height: 24px;
        }

        border: none;
        border-right: 1px solid #fadbff;
        min-height: 24px;
      }
    }

    .mat-mdc-row {
      &:nth-child(odd) {
        background: #fefaff;
      }

      .mat-mdc-cell {
        &:nth-child(1) {
          border-left: $border-color;
        }

        border-right: $border-color;
        border-bottom: $border-color;
      }
    }

    .mat-mdc-row:hover {
      background-color: lightgray;
    }
  }

  .mat-sort-header-pointer-left,
  .mat-sort-header-pointer-right,
  .mat-sort-header-pointer-middle {
    background: black;
  }
}

.slick-chips {
  .mat-mdc-standard-chip.mdc-evolution-chip--selected:not(.mdc-evolution-chip--disabled) {
    background-color: #9c27b0;
  }

  .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__checkmark {
    color: #fff;
  }

  .mat-mdc-standard-chip.mdc-evolution-chip--selected:not(.mdc-evolution-chip--disabled)
    .mdc-evolution-chip__text-label {
    color: #fff;
    font-weight: bold;
  }
}

app-new-slick {
  .mat-horizontal-stepper-header-container {
    display: none;
  }

  .mat-horizontal-content-container {
    padding: 0;
  }
}



#slickRecorder [mat-stroked-button]:not(#pauseButton),
#slickRecorder #recordButton {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

@media (min-height: 640px) {
  #slickRecorder [mat-stroked-button]:not(#pauseButton),
  #slickRecorder #recordButton {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important;
  }
}

@media (min-height: 768px) {
  #slickRecorder [mat-stroked-button]:not(#pauseButton),
  #slickRecorder #recordButton {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    padding-top: 2.8rem !important;
    padding-bottom: 2.8rem !important;
  }
}

#related_requests-panel > cdk-virtual-scroll-viewport > div.cdk-virtual-scroll-content-wrapper {
  padding: 0 !important;
}


// Use this to override the icon to make it fill instead of outlined
.icon-fill {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24 !important;
}

.custom-blur-selected > svg > path {
  fill: #83319173;
}
